<template>
  <div class="card-body p-0">
    <form
      class="form mt-2 mb-5"
      @submit.prevent="handleOperatorPhoneNumberForm"
    >
      <div class="row justify-content-start recharges-input">
        <div class="form-group mt-0 row w-100">
          <div class="col-lg-12 col-xl-6 p-l-30">
            <div v-if="isAdding || isEditing">
              <BaseInput
                :id="'phone-' + operator.id"
                v-model="numberField.phone"
                :countryCode="calling_code"
                :description="
                  $t('deposit.deposit_number') + operator.label + '.'
                "
                :disabled="loading"
                :errorMessage="phoneNumberError"
                :isPhoneInput="true"
                :label="title"
                :placeholder="
                  $t('common.number_with_space') +
                    operator.label.toString().toLowerCase()
                "
                type="number"
                @input="$v.numberField.phone.$touch()"
              />
            </div>
            <div v-else>
              <BaseInput
                v-model="code"
                :description="
                  `${$t('dashboard.labels.confirmation_code_precision')} ${
                    numberField.phone
                  }`
                "
                :errorMessage="phoneNumberError"
                :label="title"
                :placeholder="
                  $t('dashboard.placeholders.give_confirmation_code')
                "
                @input="$v.code.$touch()"
              />
            </div>
          </div>
          <div
            class="col-sm-12 col-lg-6 m-t-30 d-flex justify-content-start recharges-button-container"
          >
            <div class="text-lg-left p-r-0 mr-2">
              <button
                v-if="canCancelForm"
                class="be-btn primary  badge-failure lg contact-button"
                @click.prevent="$emit('cancel')"
                :disabled="loading"
              >
                {{ $t("common.cancel") }}
              </button>
            </div>
            <BaseButton
              :disabled="$v.$invalid || loading"
              :loading="loading"
              :show-loading="true"
              buttonClass="be-btn primary lg blue mb-0 i-left ml-2"
            >
              {{ confirmBtnLabel }}
            </BaseButton>
          </div>
        </div>
        <div
          class="form-group mt-0 row w-100 justify-content-end"
          v-if="signature && !successMessage"
        >
          <div class="col-auto">
            <BaseButton
              v-if="canShowTicketNumber"
              type="button"
              @click.prevent="handlePhoneNumberErrorRequest"
              :disabled="loadingTicket"
              :loading="loadingTicket"
              :show-loading="true"
              buttonClass="be-btn secondary sm blue mb-0 i-left ml-2"
            >
              <span :class="loadingTicket ? 'ml-2' : ''">
                {{
                  loadingTicket
                    ? "Creation du ticket"
                    : "Je n'ai pas recu le code"
                }}
              </span>
            </BaseButton>
            <span v-else class="f-w-600">
              {{ `Patienter encore ${second}s svp !` }}
            </span>
          </div>
        </div>
      </div>
      <p
        v-if="numberField.signature === null"
        class="alert-dismissible alert-bewallet-warning d-flex align-items-center border-dashed-blue"
      >
        <i class="icon-info-fill" />
        <span>
          <b>{{ $t("common.attention") }}: </b>
          {{ $t("dashboard.messages.code_sent") }}
        </span>
      </p>

      <div
        v-if="isEditing && operator.number.data.is_confirm"
        class="alert-dismissible alert-bewallet-warning d-flex align-items-center"
      >
        <i class="icon-warning-fill" />
        <span>
          {{ $t("dashboard.messages.message_price") }}
          <b>{{ `${userConfig.sms_amount} ${currency}` }}</b>
        </span>
      </div>
      <div
        v-if="successMessage"
        class="alert-dismissible alert-bewallet-warning d-flex align-items-center"
      >
        <span>{{ successMessage }}</span>
      </div>
    </form>
    <b-modal
      id="operator_number_modal"
      ref="confirmPhoneNumber"
      no-fade
      size="md"
      centered
    >
      <template slot="modal-header">
        <span />
        <span class="modal-title">
          {{ modalData && modalData.title }}
        </span>
        <span class="pointer" @click="hideModal">
          <i class="icon-close f-24" />
        </span>
      </template>
      <div class="text-center">
        <div class="info-background">
          <i class="icon-info-fill"></i>
        </div>
        {{ modalData && modalData.message }}
      </div>
      <template slot="modal-footer">
        <span class="f-w-600 fermer mr-4 pointer" @click="hideModal">
          {{ $t("common.cancel") }}
        </span>
        <button
          class="ml-4 be-btn primary lg blue"
          @click="handleOperatorPhoneNumberForm(true)"
        >
          {{ $t("common.yes_continue") }}
        </button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { maxLength, minLength, requiredIf } from "vuelidate/lib/validators";
import { phoneLengthByCallingCode } from "../../helpers/utils";

export default {
  name: "OperatorPhoneNumber",
  props: {
    operator: {
      type: Object,
      required: true,
    },
  },
  watch: {
    operator(value) {
      if (value.number.data) {
        this.numberField = Object.assign({}, value.number.data);
        this.$v.$touch();
      } else this.numberField.phone = null;
    },
  },
  data() {
    return {
      loading: false,
      loadingTicket: false,
      phoneNumberError: null,
      numberField: {
        id: null,
        users_id: null,
        operators_id: null,
        phone: null,
        is_confirm: false,
        is_edit: false,
      },
      code: null,
      signature: null,
      modalData: null,
      second: null,
      interval: null,
      successMessage: null,
    };
  },
  computed: {
    ...mapGetters("auth", ["calling_code", "userConfig", "currency"]),
    isAdding() {
      if (this.operator.number.limit) return !this.signature;
      else return this.operator.number.data === null;
    },
    isEditing() {
      return !this.isAdding && !this.signature;
    },
    isConfirmationCode() {
      return this.signature ? !this.numberField.is_confirm : false;
    },
    title() {
      return this.isAdding
        ? this.operator.number.limit
          ? this.$t("dashboard.add_recharge_number") +
            ` (${this.operator.number.numbers.length + 1}/${
              this.operator.number.limit
            })`
          : this.$t("dashboard.config_recharge_number")
        : this.isConfirmationCode
        ? this.$t("dashboard.labels.give_confirmation_code")
        : this.$t("dashboard.update_recharge_number");
    },
    confirmText() {
      return this.operator.number.limit
        ? this.$t("common.maximum") +
            ` ${this.operator.number.limit} ` +
            this.$t("deposit.cant_change_number_desc_after")
        : this.$t("dashboard.save_contact");
    },
    canCancelForm() {
      if (this.operator.number.limit)
        return this.operator.number.numbers.length > 0;
      else
        return this.operator.number.data
          ? this.operator.number.data.is_confirm
          : false;
    },
    confirmBtnLabel() {
      return this.isConfirmationCode
        ? this.$t("common.save")
        : this.$t("common.sms");
    },
    confirmBtnIcon() {
      return this.isConfirmationCode ? "fas fa-check" : "fas fa-envelope";
    },
    inputPhoneLength() {
      if (this.operator) {
        if (this.operator.country) return this.operator.country.phoneLength;
      }
      return phoneLengthByCallingCode(this.calling_code);
    },
    canSpendSms() {
      if (this.operator.number.limit)
        return this.operator.number.numbers.length >= 1;
      else return this.isEditing && this.operator.number.data.is_confirm;
    },
    canShowTicketNumber() {
      return this.signature && this.second === 0;
    },
  },
  mounted() {
    if (this.operator.number.data) {
      if (!this.operator.number.limit)
        this.numberField = Object.assign({}, this.operator.number.data);
      this.$v.$touch();
    }
  },
  methods: {
    handleOperatorPhoneNumberForm(validation = false) {
      if (!this.$v.invalid) {
        if (this.isEditing) {
          if (validation === true) {
            this.hideModal();
            this.second = 120;
            this.loading = true;
            this.$store
              .dispatch("auth/sendPhoneConfirmationCode", {
                phone: this.calling_code + this.numberField.phone,
              })
              .then(({ signature, number }) => {
                if (signature) {
                  this.signature = signature;
                  this.interval = setInterval(() => {
                    this.second--;
                  }, 1000);
                } else {
                  if (this.operator.number.limit)
                    this.$store.commit("deposit/ADD_OPERATOR_NUMBER", {
                      number: number,
                      operator: this.operator,
                    });
                  else
                    this.$store.commit("deposit/SET_OPERATOR_NUMBER", {
                      number: number,
                      operator: this.operator,
                    });
                  this.$emit("cancel");
                }
                this.loading = false;
              })
              .catch(err => {
                if (err.response.data.message)
                  this.phoneNumberError = err.response.data.message;
                this.loading = false;
              });
          } else
            this.openModal({
              title: this.$t("dashboard.labels.did_you_want_to_save"),
              message: this.confirmText,
            });
        } else {
          if (validation === true) {
            this.hideModal();
            this.loading = true;
            this.second = 120;
            this.numberField.operators_id = this.operator.id;
            this.$store
              .dispatch("deposit/setOperatorNumber", {
                operator: this.operator,
                number: this.numberField,
                code: this.code,
                signature: this.signature,
              })
              .then(({ number, signature }) => {
                if (number.is_confirm) this.$emit("cancel");
                else {
                  this.signature = signature;
                  this.numberField = number;
                  this.interval = setInterval(() => {
                    this.second--;
                    if (this.second === 0) clearInterval(this.interval);
                  }, 1000);
                }
                this.loading = false;
              })
              .catch(err => {
                if (err.data.message) this.phoneNumberError = err.data.message;
                this.loading = false;
              });
          } else
            this.openModal({
              title: this.$t("dashboard.labels.did_you_want_to_save"),
              message: this.confirmText,
            });
        }
      }
    },

    handlePhoneNumberErrorRequest() {
      this.loadingTicket = true;
      this.$store
        .dispatch("assistance/addNewNumberTicket", {
          model_id: this.numberField.id,
          operators_id: this.operator.id,
          phone: this.numberField.phone,
        })
        .then(response => {
          this.successMessage = response.message;
          this.loadingTicket = false;
          this.$store.dispatch("assistance/getTicketList", {
            page: 1,
            field: {
              paginate: 10,
              is_archive: false,
              is_active: true,
              users_id: this.numberField.users_id,
              order_by: "updated_at",
              direction: "DESC",
            },
          });
        })
        .catch(error => {
          console.log(error);
          this.loadingTicket = false;
        });
    },

    openModal(row) {
      this.modalData = row;
      this.$refs.confirmPhoneNumber.show();
      this.phoneNumberError = null;
    },
    hideModal() {
      this.$refs.confirmPhoneNumber.hide();
    },
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  validations() {
    return {
      numberField: {
        phone: {
          required: requiredIf(() => !this.isConfirmationCode),
          minLength: minLength(this.inputPhoneLength),
          maxLength: maxLength(this.inputPhoneLength),
          customEdit: value =>
            this.operator.number.data && this.operator.number.data.is_confirm
              ? value !== this.operator.number.data.phone
              : true,
        },
      },
      code: {
        required: requiredIf(() => this.signature),
        minLength: minLength(4),
      },
    };
  },
};
</script>

<style scoped></style>
