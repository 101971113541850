<template>
  <div class="d-flex mt-4 mt-lg-0">
    <div class="w-100px col-11 col-lg-10 flex-1 px-0 pr-2">
      <be-multiselect
        v-model="phone_number_select"
        :inputLabel="inputLabel"
        :options="remappedNumbers"
        :placeholder="$t('common.choose')"
        close-on-select
        label="phone"
        onlyKey
        track-by="phone"
        @input="closePlaceholder"
      />
    </div>
    <div class="edit-btn mt-4 pt-2">
      <button
        :disabled="!enabledActionBtn"
        :title="canEditNumber ? $t('common.update') : $t('common.add')"
        class="be-btn secondary blue circle-btn lg"
        role="button"
        type="button"
        @click="openUserServiceForm"
      >
        <i :class="canEditNumber ? 'icon-edit' : 'icon-plus'"></i>
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { formFieldMixin } from "../../mixins/formFieldMixin";
import BeMultiselect from "../common/BeMultiselect";

export default {
  name: "PhoneNumberSelect",
  components: { BeMultiselect },
  mixins: [formFieldMixin],

  props: {
    number: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    onToggleEdit: {
      type: Function,
      default: () => {},
    },
    inputLabel: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      phone_number_select: null,
      showNumberForm: false,
    };
  },

  mounted() {
    if (this.isPhoneInput) this.$emit("input", this.phone);
  },

  computed: {
    ...mapGetters("auth", ["calling_code"]),
    enabledActionBtn() {
      return this.canEditNumber || this.canAddNumber;
    },
    canEditNumber() {
      if (this.loading) return false;
      return this.number.is_edit;
    },
    canAddNumber() {
      return this.number.limit > this.number.numbers.length;
    },
    remappedNumbers() {
      return this.number.numbers.filter(p => p.is_confirm);
    },

    canShowCancelBtn() {
      return this.remappedNumbers.length >= 0;
    },
  },

  methods: {
    openUserServiceForm() {
      if (this.canEditNumber || this.canShowCancelBtn) {
        if (!this.canEditNumber) this.$emit("input", null);
        this.onToggleEdit(true);
      }
    },
    closePlaceholder(val) {
      this.$emit("input", this.phone_number_select);
      this.onToggleEdit(val === "-1");
    },
  },
};
</script>

<style scoped></style>
