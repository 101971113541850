<template>
  <div>
    <div>
      <BaseInput
        v-if="!isGenderSelect"
        :type="inputPropertyType"
        :label="label"
        :class="disabledField && canEditPhone ? 'col-10 f-l pl-0' : ''"
        :isInvalid="isInvalid"
        :isPhoneInput="phoneInput"
        :countryCode="calling_code"
        :value="value"
        :disabled="!!disabledField"
        v-bind="$attrs"
        v-on="$listeners"
        :errorMessage="errorMessage"
      />
      <div
        class="edit-btn f-l col-2 m-t-30"
        v-if="disabledField && canEditPhone"
      >
        <button
          role="button"
          type="button"
          class="be-btn secondary blue circle-btn lg"
          title="Modifier"
          @click="$emit('editPhone')"
        >
          <i class="icon-edit"></i>
        </button>
      </div>
      <select
        v-if="isGenderSelect"
        class="form-control"
        :value="value"
        v-on="$listeners"
        v-bind="$attrs"
        :class="{ 'is-invalid': isInvalid }"
        @change="updateValue"
      >
        <option disabled :value="undefined">{{ $t("common.choose") }}</option>
        <option value="male">{{ $t("fields.gender.male") }}</option>
        <option value="female">{{ $t("fields.gender.female") }}</option>
      </select>
      <slot v-if="!this.phoneInput" />
    </div>
    <slot v-if="this.phoneInput" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { formFieldMixin } from "../../mixins/formFieldMixin";

export default {
  name: "PropertyInput",
  mixins: [formFieldMixin],

  props: {
    phone: {
      type: [String, Object],
      default: null,
    },
    property: {
      type: Object,
      required: true,
    },
    canEditPhone: {
      type: Boolean,
      default: true,
    },
  },

  mounted() {
    if (this.phoneInput) this.$emit("input", this.phone);
  },

  computed: {
    ...mapGetters("auth", ["calling_code"]),
    phoneInput() {
      return this.property.code === "phone";
    },
    isGenderSelect() {
      return this.property.code === "userGender";
    },
    inputClass() {
      return this.phoneInput ? "input-group input-group-sm" : "form-group";
    },
    inputPropertyType() {
      return this.property.code === "phone"
        ? "number"
        : this.property.code === "email"
        ? "email"
        : "text";
    },
    disabledField() {
      return this.phoneInput && this.phone;
    },
  },
};
</script>

<style scoped></style>
