<template>
  <div
    class="confirm-container deposit-confirm d-flex flex-column align-items-center justify-content-center"
  >
    <div class="mb-2">
      <img
        :alt="$t('deposit.scan_code')"
        :src="qr_code_url"
        class="img-thumbnail shadow-sm"
      />
    </div>
    <a
      v-clipboard="blockchain.address"
      :title="blockchain.copy"
      class="ml-2"
      href="#"
      @error="handleError"
      @success="handleSuccess"
      @click.prevent
      ><h2 class="f-w-600">{{ blockchain.copy }}</h2></a
    >
    <h5 class="text-center">
      {{ blockchain.label_message }}
      <b>{{ blockchain.priority }} ${{ blockchain.usd_amount }} Bitcoin.</b>
    </h5>

    <p class="mt-2 f-w-500 text-center">
      <em class="mt-2 f-w-400">{{ blockchain.note }}</em>
    </p>
    <div class="loading-container">
      <div></div>
    </div>
  </div>
</template>
<script>
import { clipboardMixin } from "../../mixins/clipboardMixin";

export default {
  name: "BlockchainQRCode",
  mixins: [clipboardMixin],
  props: {
    blockchain: {
      type: Object,
      required: true,
    },
  },
  computed: {
    qr_code_url() {
      return (
        "https://chart.googleapis.com/chart?cht=qr&chl=bitcoin:" +
        this.blockchain.address +
        "?amount=" +
        this.blockchain.btc_amount +
        "&label=" +
        this.blockchain.label +
        "&description=" +
        this.blockchain.message +
        "&chs=216x216&choe=UTF-8&chld=L|2"
      );
    },
  },
};
</script>
