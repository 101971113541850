<template>
  <div class="retraits-service d-flex h-10 be-row justify-content-center">
    <div class="left-side d-none d-lg-flex">
      <div class="icon-div">
        <i class="icon-deposit-fill"></i>
      </div>
      <span>
        {{ $t("deposit.do_") }} <br />
        {{ $t("deposit.a_deposit") }}
      </span>
    </div>
    <div class="right-side col-xl-8 px-0">
      <div class="be-card col-xl-12 px-0">
        <div class="position-relative">
          <header-with-divider
            :color="'blue'"
            :text="`${$t('deposit.do_')} ${$t('deposit.a_deposit')}`"
          />
        </div>
        <div v-if="isDepositAvailable" class="h-100">
          <form
            v-if="isDepositForm"
            class="be-card-body h-100"
            @submit.prevent="handleDepositForm"
          >
            <be-form-error v-if="error" :message="error" />
            <div class="be-row position-relative">
              <div class="col-lg-6 px-0 pr-lg-4">
                <be-multiselect
                  v-model="operator_id"
                  :close-on-select="true"
                  :inputLabel="$t('common.choose_an_operator')"
                  :options="operators"
                  :placeholder="$t('common.choose_an_operator')"
                  label="label"
                  onlyKey
                  track-by="id"
                  withImages
                />
              </div>
            </div>
            <div class="card-body p-0 p-sm-2">
              <div class="divider my-30"></div>
              <OperatorPhoneNumber
                v-if="showConfigOperatorNumber"
                :operator="operator"
                @cancel="closeOperatorPhoneEditing"
              />
              <template v-else>
                <div class="be-row p-2 p-sm-0">
                  <template v-if="isWaveOperator">
                    <div class="col-12 px-0">
                      {{ $t("common.wave_alert") }}
                    </div>
                    <div class="divider my-3"></div>
                  </template>
                  <template v-if="properties.length > 0">
                    <div
                      v-for="property in properties"
                      :key="property.id"
                      class="col-xs-12 col-lg-6 px-0 pr-lg-4"
                    >
                      <template v-if="property.code === 'phone'">
                        <PropertySelect
                          v-model="form[property.code]"
                          :number="operator.number"
                          :onToggleEdit="onToggleEdit"
                          :loading="loading"
                          :input-label="property.label"
                          v-if="
                            havePropertyCode('phone') &&
                              property.code === 'phone' &&
                              operatorPhoneValue &&
                              operator.number.limit
                          "
                        />
                        <PropertyInput
                          v-else
                          v-model="form[property.code]"
                          :can-edit-phone="canEditOperatorPhoneValue"
                          :errorMessage="propertyInputValidation(property)"
                          :is-invalid="$v.form[property.code].$error"
                          :label="property.label"
                          :phone="operatorPhoneValue"
                          :placeholder="
                            $t('common.insert_the') + ' ' + property.label
                          "
                          :property="property"
                          @blur="$v.form[property.code].$touch()"
                          @editPhone="openOperatorPhoneEditing"
                        />
                      </template>
                      <PropertyInput
                        v-else
                        v-model="form[property.code]"
                        :errorMessage="propertyInputValidation(property)"
                        :is-invalid="$v.form[property.code].$error"
                        :label="property.label"
                        :placeholder="
                          $t('common.insert_the') + ' ' + property.label
                        "
                        :property="property"
                        @blur="$v.form[property.code].$touch()"
                      />
                    </div>
                  </template>
                  <div class="col-xs-12 col-lg-6 px-0 deposit-input-div">
                    <BaseInput
                      v-if="!canSelectDepositAmount"
                      v-model.number="amount"
                      :disabled="operator === null"
                      :errorMessage="montantInputValidation"
                      :isInvalid="$v.amount.$error"
                      :label="$t('deposit.deposit_amount')"
                      :placeholder="$t('deposit.insert_the_amount')"
                      type="number"
                      @input="$v.amount.$touch()"
                    />
                    <be-multiselect
                      v-else
                      v-model.number="amount"
                      :close-on-select="true"
                      :disabled="amount_list.length === 0"
                      :errorMessage="montantInputValidation"
                      :inputLabel="$t('deposit.deposit_amount')"
                      :isInvalid="$v.amount.$error"
                      :options="amount_list"
                      :placeholder="$t('deposit.choose_deposit_amount')"
                      label="label"
                      onlyKey
                      track-by="id"
                      @input="$v.amount.$touch()"
                    />
                  </div>
                </div>
              </template>
              <div class="divider my-30"></div>
            </div>

            <div class="be-row justify-content-end">
              <div
                class="retrait-main-buttons col be-row justify-content-center col-sm-auto px-0"
              >
                <span
                  class="f-w-600 fermer pointer retrait-annuler"
                  @click="$router.push({ name: 'deposit' }).catch(() => {})"
                >
                  {{ $t("common.return") }}
                </span>
                <BaseButton
                  :disabled="$v.$invalid || loading"
                  :loading="loading"
                  :show-loading="true"
                  buttonClass="ml-4 be-btn primary lg blue mb-0"
                >
                  {{ $t("deposit.start_a_deposit") }}
                </BaseButton>
              </div>
            </div>
          </form>

          <DepositInProcess v-if="depositInit" :data="depositInit" />

          <BlockchainQRCode
            v-if="blockchainInit"
            :blockchain="blockchainInit.data"
          />
        </div>
        <div v-else>
          <BaseEmptyList :model="listModel" />
        </div>
      </div>
    </div>
    <b-modal id="deposit_modal" ref="detailsModal" no-fade size="lg" top>
      <template slot="modal-header">
        <span />
        <span class="modal-title">
          {{ modalData && modalData.title }}
        </span>
        <span class="pointer" @click="hideModal">
          <i class="icon-close f-24" />
        </span>
      </template>
      <div class="text-center">
        <div class="info-background">
          <i class="icon-info-fill"></i>
        </div>
        {{ modalData && modalData.message }}
      </div>
      <template slot="modal-footer">
        <span class="f-w-600 fermer mr-4 pointer" @click="hideModal">
          {{ $t("common.cancel") }}
        </span>
        <button class="ml-4 be-btn primary lg blue" @click="processToDeposit">
          {{ $t("common.yes_continue") }}
        </button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  maxValue,
  minValue,
  required,
  email,
  requiredIf,
  minLength,
  maxLength,
} from "vuelidate/lib/validators";
import PropertyInput from "../../components/deposit/PropertyInput";
import DepositInProcess from "../../components/deposit/DepositInProcess";
import BlockchainQRCode from "../../components/deposit/BlockchainQRCode";
// import BaseChooseElement from '../../components/common/BaseChooseElement';
import BeMultiselect from "../../components/common/BeMultiselect";
import {
  getAmountValidationStep,
  getOTPStartCodeByPropertyCode,
  phoneLengthByCallingCode,
} from "../../helpers/utils";
import OperatorPhoneNumber from "../../components/deposit/OperatorPhoneNumber";
import {
  emptyListModel,
  intouch_amount_list,
  operatorCode,
  serverApiCode,
} from "../../helpers/constants";
// import BaseSelect from '../../components/common/BaseSelect';
import HeaderWithDivider from "../../components/common/HeaderWithDivider";
import BeFormError from "../../components/common/BeFormError";
import PropertySelect from "../../components/deposit/PhoneNumberSelect";

export default {
  name: "DepositForm",
  components: {
    PropertySelect,
    OperatorPhoneNumber,
    BlockchainQRCode,
    DepositInProcess,
    PropertyInput,
    HeaderWithDivider,
    BeMultiselect,
    BeFormError,
  },

  data() {
    return {
      modalData: null,
      loading: false,
      operator_id: null,
      amount: null,
      depositInit: null,
      blockchainInit: null,
      form: {},
      error: null,
      errors: [],
      interval: null,
      timeout: null,
      isOperatorNumberForm: false,
      editOperatorPhone: false,
    };
  },

  watch: {
    operator_id() {
      this.form = {};
      this.isOperatorNumberForm = false;
      this.editOperatorPhone = false;
    },
  },

  beforeDestroy() {
    if (this.interval) clearInterval(this.interval);
    if (this.timeout) clearTimeout(this.timeout);
  },

  computed: {
    ...mapGetters("deposit", [
      "operators",
      "isDepositAvailable",
      "getOperatorById",
    ]),
    ...mapGetters("auth", ["userConfig", "calling_code", "currency"]),

    listModel() {
      return emptyListModel.disableDeposit;
    },
    operator() {
      if (this.operator_id) return this.getOperatorById(this.operator_id);
      else return null;
    },
    otpPropertyCode() {
      if (this.operator) {
        const property = this.operator.properties.find(p => p.code === "otp");
        if (property) {
          const code = getOTPStartCodeByPropertyCode(this.operator.code);
          if (
            code &&
            code.toString().charAt(code.toString().length - 1) === "*"
          )
            return (
              code +
              (this.amount
                ? this.amount
                : this.$t("common.amount").toUpperCase()) +
              "#"
            );
          else return code;
        }
      }

      return null;
    },
    properties() {
      if (this.operator)
        return this.operator.properties.filter(p => p.is_active);
      else return [];
    },
    isDepositForm() {
      return !this.depositInit && !this.blockchainInit;
    },
    minDepositAmountValue() {
      if (this.operator)
        return this.operator.depot_min
          ? parseFloat(this.userConfig.rate_from_cfa) *
              parseFloat(this.operator.depot_min)
          : this.userConfig.depot_min;
      else return this.userConfig.depot_min;
    },
    maxDepositAmountValue() {
      if (this.operator)
        return this.operator.depot_max
          ? parseFloat(this.userConfig.rate_from_cfa) *
              parseFloat(this.operator.depot_max)
          : this.userConfig.depot_max;
      else return this.userConfig.depot_max;
    },
    inputPhoneLength() {
      if (this.operator) {
        if (this.operator.country) return this.operator.country.phoneLength;
      }
      return phoneLengthByCallingCode(this.calling_code);
    },
    showConfigOperatorNumber() {
      if (this.operator) {
        if (this.operator.number.check) {
          if (this.isOperatorNumberForm) return true;
          else {
            if (this.operator.number.limit)
              return this.operator.number.numbers.length === 0;
            else
              return this.operator.number.data !== null
                ? !this.operator.number.data.is_confirm
                : true;
          }
        } else return false;
      }
      return false;
    },
    haveSelectPhoneField() {
      return (
        this.havePropertyCode("phone") &&
        this.operatorPhoneValue &&
        this.operator.number.limit
      );
    },
    operatorPhoneValue() {
      if (this.operator) {
        return this.operator.number.check
          ? this.operator.number.limit
            ? this.operator.number.numbers[0]
            : this.operator.number.data.phone
          : null;
      }
      return null;
    },
    canEditOperatorPhoneValue() {
      if (this.operator) {
        if (this.operator.number.limit) return false;
        else
          return this.operator.number.data
            ? this.operator.number.data.editable
            : true;
      }
      return true;
    },
    amount_list() {
      const amounts = Object.assign([], intouch_amount_list);
      if (this.operator.code === operatorCode.moovMoneyCIV)
        amounts.unshift(...[1000, 1500]);
      return amounts.map(amount => ({ id: amount, label: amount }));
    },
    canSelectDepositAmount() {
      return this.operator
        ? this.operator.api_depot === serverApiCode.intouch
        : false;
    },
    amountValidationStep() {
      return this.operator
        ? getAmountValidationStep(this.operator.api_depot, 50)
        : 50;
    },
    montantInputValidation() {
      if (this.$v.amount.$error) {
        if (!this.$v.amount.required) {
          return (
            this.$t("common.please_") +
            ` ${
              this.canSelectDepositAmount
                ? this.$t("common.choose_")
                : this.$t("common.input")
            } ` +
            this.$t("deposit.the_deposit_amount")
          );
        }
        if (!this.$v.amount.minValue) {
          return (
            this.$t("deposit.deposit_amount_must_be_greater_than") +
            ` ${this.minDepositAmountValue} ${this.currency}`
          );
        }
        if (!this.$v.amount.maxValue) {
          return (
            this.$t("deposit.deposit_amount_must_be_lower_than") +
            ` ${this.maxDepositAmountValue} ${this.currency}`
          );
        }
      }
      return "";
    },
    isWaveOperator() {
      return this.operator
        ? this.operator.code === operatorCode.waveCIV &&
            this.havePropertyCode("name")
        : false;
    },
  },

  methods: {
    handleDepositForm() {
      if (!this.$v.invalid) {
        if (this.operator.with_taxe && this.operator.internal_tax) {
          this.loading = true;
          this.$store
            .dispatch("deposit/getDepositTaxAmountDetail", {
              operator: this.operator,
              amount: this.amount,
            })
            .then(data => {
              this.loading = false;
              data.title = this.$t("common.important");
              data.message = this.$t("deposit.deposit_error", {
                amount: data.amount,
                currency: this.currency,
                tax_value: data.tax_value,
                operator: this.operator.label,
                amount_with_tax: data.amount_with_tax,
              });
              this.openModal(data);
            });
        } else if (this.operator.one_dollar_in) {
          const btcAmount =
            parseFloat(this.amount) / parseFloat(this.operator.one_dollar_in);
          const data = [];
          data.title = this.$t("deposit.recharge_of_var", {
            amount: btcAmount.toFixed(2),
            operator: this.operator.one_dollar_in,
            currency: this.currency,
          });
          data.message = this.$t("deposit.attention_value_can_change");
          this.openModal(data);
        } else if (this.isWaveOperator) {
          this.openModal({
            title: this.$t("common.important"),
            message: this.$t("common.wave_confirm", {
              name: this.form["name"],
              amount: this.amount,
            }),
          });
        } else this.processToDeposit();
      }
    },

    propertyInputValidation(property) {
      if (property) {
        if (property.code === "otp" && this.otpPropertyCode) {
          return (
            this.$t("deposit.dial_the_code") +
            ` ${this.otpPropertyCode} ` +
            this.$t("deposit.dial_the_code_for")
          );
        }
        if (this.$v.form[property.code].$error || this.errors[property.code]) {
          if (this.errors[property.code]) {
            return this.errors[property.code][0];
          }
          if (!this.$v.form[property.code].required) {
            return this.$t("deposit.please_input_the_var_value", {
              label: property.label,
            });
          } else {
            if (this.havePropertyCode("phone") && property.code === "phone") {
              if (
                !this.$v.form[property.code].minLength ||
                !this.$v.form[property.code].maxLength
              ) {
                return this.$t("deposit.the_var_value_is_length_not_valid", {
                  label: property.label,
                  length: this.inputPhoneLength,
                });
              }
            }
            if (
              this.havePropertyCode("email") &&
              property.code === "email" &&
              !this.$v.form[property.code].email
            ) {
              return this.$t("deposit.the_var_value_is_not_valid", {
                label: property.label,
              });
            }
            if (
              this.havePropertyCode("userEmail") &&
              property.code === "userEmail" &&
              !this.$v.form[property.code].userEmail
            ) {
              return this.$t("deposit.the_var_value_is_not_valid", {
                label: property.label,
              });
            }
          }
        }
      }
      return "";
    },

    processToDeposit() {
      this.hideModal();
      this.loading = true;
      this.error = null;
      this.$store
        .dispatch("deposit/initiateDeposit", {
          operator_id: this.operator_id,
          amount: this.amount,
          form: this.form,
        })
        .then(response => {
          switch (response.status) {
            case 200:
              this.$router.push({ name: "deposit" });
              break;
            case 201:
              this.depositInit = response.data;
              this.startDepositVerification(response.data.deposit.id);
              break;
            case 202:
              this.blockchainInit = response.data;
              this.startDepositVerification(response.data.deposit.id);
              break;
          }
        })
        .catch(error => {
          if (error.data.message) {
            this.error = error.data.message;
          }
          switch (error.status) {
            case 307:
              location.assign(error.data.url);
              break;
            case 422:
              this.errors = error.response.data.errors;
              break;
          }
          this.loading = false;
        });
    },
    openModal(row) {
      this.modalData = row;
      this.$refs.detailsModal.show();
    },
    hideModal() {
      this.$refs.detailsModal.hide();
    },
    startDepositVerification(deposit_id) {
      if (!this.$store.state.echo_connect) {
        this.manuelVerification(deposit_id);
      }
      this.$echo
        .channel(`deposit-echo-${deposit_id}`)
        .listen("Deposit\\DepositUpdatedNotify", payload => {
          switch (payload.status) {
            case 200:
            case 400:
              if (payload.status === 200)
                this.$store.dispatch("auth/getCurrentUser");

              this.$store.dispatch("notification/add", {
                type: payload.status === 200 ? "success" : "danger",
                message: payload.response.message,
                is_toast: false,
              });
              this.$router.push({ name: "deposit" });
              break;
            case 201:
              if (
                this.depositInit &&
                !this.depositInit.validate &&
                payload.response.validate
              )
                this.depositInit = payload.response;
              break;
            case 202:
              this.blockchainInit = payload.response;
              break;
          }
        });
      this.loading = false;

      this.timeout = setTimeout(
        () => this.manuelVerification(deposit_id),
        120000
      );
    },

    manuelVerification(deposit_id) {
      this.interval = setInterval(() => {
        this.$store
          .dispatch("deposit/verifyDeposit", deposit_id)
          .then(response => {
            switch (response.status) {
              case 200:
                this.$router.push({ name: "deposit" });
                break;
              case 201:
                if (
                  this.depositInit &&
                  !this.depositInit.validate &&
                  response.data.validate
                )
                  this.depositInit = response.data;
                break;
              case 202:
                this.blockchainInit = response.data;
                break;
            }
          })
          .catch(() => {
            this.$router.push({ name: "deposit" });
          });
      }, 10000);
    },

    havePropertyCode(code) {
      return this.properties.find(p => p.code === code) !== undefined;
    },

    onToggleEdit(value) {
      this.isOperatorNumberForm = value;
    },

    openOperatorPhoneEditing() {
      this.isOperatorNumberForm = true;
    },

    closeOperatorPhoneEditing() {
      this.isOperatorNumberForm = false;
      this.editOperatorPhone = false;
    },
  },

  validations() {
    return {
      operator_id: { required },
      amount: {
        required,
        minValue: minValue(this.minDepositAmountValue),
        maxValue: maxValue(this.maxDepositAmountValue),
        validAmount: value => {
          if (value) {
            return parseFloat(this.amount) % this.amountValidationStep === 0;
          } else return true;
        },
      },
      form: {
        phone: {
          required: requiredIf(() => this.havePropertyCode("phone")),
          minLength: minLength(this.inputPhoneLength),
          maxLength: maxLength(this.inputPhoneLength),
        },
        email: {
          required: requiredIf(() => this.havePropertyCode("email")),
          email,
        },
        otp: { required: requiredIf(() => this.havePropertyCode("otp")) },
        nom: { required: requiredIf(() => this.havePropertyCode("nom")) },
        prenom: { required: requiredIf(() => this.havePropertyCode("prenom")) },
        userEmail: {
          required: requiredIf(() => this.havePropertyCode("userEmail")),
          email,
        },
        userLastname: {
          required: requiredIf(() => this.havePropertyCode("userLastname")),
        },
        userFirstname: {
          required: requiredIf(() => this.havePropertyCode("userFirstname")),
        },
        userGender: {
          required: requiredIf(() => this.havePropertyCode("userGender")),
        },
        name: {
          required: requiredIf(() => this.havePropertyCode("name")),
        },
      },
    };
  },
};
</script>

<style scoped></style>
